.App {
  text-align: center;
  min-height: 100vh; /* Ensure it takes full viewport height */
}

body {
  background-color: #222;
}

.h1{
  color: white;
}

.h2{
  color: white;
}

.larger-header {
  text-align: center; /* Center-aligns the header */
  margin: 40px 0; /* Adds vertical spacing around the header */
}

.larger-header h1 {
  font-size: 4.5rem; /* Increase this value to make the header larger */
  font-weight: bold;
  font-family: Palatino;
  color: rgb(255, 255, 255);
}

.rectangle {
  width: 400px;
  height: 100px;
  margin: 20px auto; /* Center-align each rectangle */
  padding: 20px;
  border: 5px solid #000;
  border-image: linear-gradient(#e66465, #9198e5) 1;
  display: block;   /* Ensure it's a block-level element */
  background-color: rgb(237, 238, 244);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.rectangle-first {
  margin-top: 50px; /* Move the first rectangle down */
}

.rectangle:hover {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.7); /* Blue glow */
  transform: scale(1.05); /* Slightly enlarge the rectangle */
}

.assignments {
  color: white;
}

.events {
  color: black;
}

.classes{
  color: black;
}

.available-times{
  color: red;
}